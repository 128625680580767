import request from "@/utils/request";

// 工时列表
export function list(data) {
  return request({
    url: "/project/taskTime/list",
    method: "post",
    data,
  });
}

// 登记工时
export function add(data) {
  return request({
    url: "/project/taskTime/add",
    method: "post",
    data,
  });
}

// 任务项查询分页
export function taskList(data) {
  return request({
    url: "/project/taskTime/task-list",
    method: "post",
    data,
  });
}
// 任务项查询不分页
export function taskListAll(data) {
  return request({
    url: "/project/taskTime/task-listAll",
    method: "post",
    data,
  });
}