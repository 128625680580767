<template>
  <div>
    <div class="antd-table-search-wrap">
      <a-row :gutter="12">
        <a-form :form="form" :wrapper-col="{ span: 24 }">
          <a-col :span="2">
            <a-form-item>
              <a-button type="primary" @click="createFormEvt">
                + 登记工时</a-button
              >
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item>
              <a-range-picker
                style="width: 100%"
                format="YYYY-MM-DD"
                v-model="timeRange"
                @change="dateChange"
              >
                <a-icon slot="suffixIcon" type="calendar" />
              </a-range-picker>
              <p style="color:red">提示：系统最多只显示15天的数据</p>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </div>
    <div class="antd-page-content">
      <a-table
        ref="table"
        rowKey="taskId"
        :columns="columns"
        :data-source="tableData"
        :scroll="{ x: 800 }"
      >
      </a-table>
    </div>

    <!-- 登记工时 -->
    <add-form
      v-if="addFormVisible"
      ref="addForm"
      @refreshDataList="loadData"
    ></add-form>
  </div>
</template>

<script>
import moment from "moment";
import addForm from "./components/taskTimeAdd";
import { list } from "@/httpsAPI/workplace/working";
export default {
  name: "project",
  data() {
    return {
      columns: [
        {
          title: "工作项名称",
          dataIndex: "projectName",
          key: "projectName",
          width: 200,
        },
        {
          title: "总计",
          dataIndex: "sumTime",
          key: "sumTime",
          width: 100,
        },
      ],
      tableData: [],
      timeRange: [],
      startDate: "2021-11-09",
      endDate: "2021-11-09",
      form: this.$form.createForm(this),
      addFormVisible: false,
      confirmLoading: false,
    };
  },
  components: {
    addForm,
  },
  created() {
    this.timeRange = [
      moment().subtract(14, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
    this.loadData();
  },
  methods: {
    dateChange(date, dateString) {
      this.startDate = dateString[0];
      this.endDate = dateString[1];
      this.loadData();
    },
    // 加载数据
    loadData() {
      const that = this
      const params = {
        startDate: moment(this.timeRange[0]).format("YYYY-MM-DD"),
        endDate: moment(this.timeRange[1]).format("YYYY-MM-DD"),
      };
      list(params).then((res) => {
        if(res.code==10000){
          const sdata = res.data.row;
  
          that.formatColomnsData(sdata, this.columns);
          that.tableData = res.data.data;
        }else{
           that.$message.error({
              content: res.msg,
              duration: 3
          })
        }
      });
    },
    // 格式化表头数据
    formatColomnsData(sdata, colomns) {
      let arr = [];
      for (let i = 0; i < sdata.length; i++) {
        const item = {
          title: sdata[i].startTimeStr + " " + sdata[i].week,
          dataIndex: sdata[i].startTimeStr,
          key: sdata[i].startTimeStr,
          width: 60,
        };
        arr.push(item);
      }
      colomns = [
        {
          title: "工作项名称",
          dataIndex: "title",
          key: "title",
          width: 200,
        },
        {
          title: "总计",
          dataIndex: "sumTime",
          key: "sumTime",
          width: 80,
        },
      ];
      colomns = colomns.concat(arr);
      return (this.columns = colomns);
    },
    refresh() {
      this.$refs.table.refresh(true);
    },
    // 登记工时
    createFormEvt() {
      this.addFormVisible = true;
      this.$nextTick(() => {
        this.$refs.addForm.init(this.$route.query.projectId);
      });
    },
  },
};
</script>

<style scoped></style>
